<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-01-31 11:17:48
 * @LastEditors: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @LastEditTime: 2024-06-01 14:14:25
 * @FilePath: \an-pc\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div id="app" :style="{'background-color':appBg}">
		<AppNav v-if="$route.meta.isShowNav" />
		<!-- <AppHeader v-if="this.$route.meta.isShowHeader" />
		<AppHeaderSticky v-if="this.$route.meta.isShowHeaderSticky" /> -->
		<router-view v-if="getIsMini()"></router-view>
		<div v-else class="container">
			<div :class="$route.meta.hideAppNavMenu?'':'flex-start-2'">
				<AppNavMenu v-if="!$route.meta.hideAppNavMenu" />
				<keep-alive :include="['home','estimate','vehicleService','traceability']">
					<router-view></router-view>
				</keep-alive>
			</div>			
		</div>
		<!-- 支付弹框 -->
		<AppPay />
		<!-- 询价弹框 -->
		<AppDialogMobile />
		<!-- 引导弹框 -->
		<guideMask />
		<!-- 服务商选择弹框 -->
		<serviceMask />
		<!-- <AppFooter v-if="$route.meta.isShowFooter" /> -->
	</div>
</template>

<script>
import AppNav from '@/components/app-nav';
import AppFooter from '@/components/app-footer';
import AppNavMenu from '@/components/app-nav-menu.vue';
import AppPay from '@/components/app-pay';
import AppDialogMobile from '@/components/app-dialog-mobile';
import guideMask from '@/components/guide-mask';
import serviceMask from '@/components/mask-service-provider';


// import AppHeader from '@/components/app-header';
// import AppHeaderSticky from '@/components/app-header-sticky';
export default {
	name: 'App',
	components: { 
		AppNav, 
		AppFooter, 
		AppNavMenu,
		AppPay,
		AppDialogMobile,
		guideMask,
		serviceMask
		// AppHeader, 
		// AppHeaderSticky 
	},
	data() {
		return {
			isShowContainer:true,
			appBg:'white',
		}
	},
	watch: {
		$route(to,form){
			console.log(to);
			let body = document.querySelector('body');
			if(to.path == '/home'){
				body.style.backgroundColor = 'white';
				this.appBg = 'white';
			}else{
				body.style.backgroundColor = '#F3F3F3';
				this.appBg = '#F3F3F3';
			}
		}
	},
	created() {
		// this.$store.dispatch('category/getCategoryDataList');
		if (this.$store.state.user.token) {
			// this.$store.dispatch('cart/getCartDataList');
		}
	},
	methods: {
		getIsMini(){
			return this.$route.path === '/' || this.$route.meta.isMiniWeb
		}
	},
};
</script>
<style>
	.el-checkbox .el-checkbox__input.is-checked+.el-checkbox__label{
        color: #093F87;
    }
  .el-checkbox  .el-checkbox__input.is-checked .el-checkbox__inner{
        background-color: #093F87;
        border-color: #093F87;
    }
</style>
<style lang="less" scoped>
#app{
	background-color: white;
}
</style>