import request from '@/utils/request';

export const login = data => {
	return request({
		url: '/login',
		method: 'post',
		data,
		header: { 'Content-Type': 'application/json' },
	});
};

export const reqSmsCode = data =>{
	return request({
		url:'system-boot/sms-code',
		method: 'post',
		params:data,
		headers: { 
			'Content-Type': 'application/json',
			Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}
export const reqMobileLogin = data =>{
	return request({
		url:'an-auth/oauth2/token',
		method: 'post',
		params:data,
		headers: { 
			'Content-Type': 'application/json',
			Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

