<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-05-13 17:18:26
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-16 13:42:52
 * @FilePath: \an-pc\src\components\mask-service-provider.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog center append-to-body class="servider-box" title="选择第三方服务商进行下单" :visible.sync="$store.state.common.showServiceMask" width="800px">
    <div class="flex-sb">
      <div v-for="(item,i) in $store.state.common.serviceList" :key="i" class="servider-c">
        <div class="flex-col-center servider-item">
          <div @mouseenter="item.hover = true" @mouseleave="item.hover = false" class="img-box">
            <img :src="item.logoUrl" alt="">
            <img class="foolter-img" :class="item.hover?'foolter-img-css3':''" :src="item.appQrCode" alt="">
          </div>
          <b>{{item.brandName}}</b>
          <div class="flex-center">
            <p>本次服务由{{item.brandName}}提供</p>
           
              <div class="mask_my_popover_icon" style="position: relative;">
                <i slot="reference" class="el-icon-warning-outline" style="cursor: pointer;"></i>
                <div class="mask_my_popover el-popover el-popper el-popover--plain" style="max-width: 150px;top:20px;left: 50%;transform: translateX(-50%);">
                  <!-- <div class="el-popover__title">{{item.brandName}}</div> -->
                  {{item.bak}}
                  <div class="popper__arrow service_popover_sanjiao" 
                  style="margin-right: 3px;border-top-width: 0px;
                  border-bottom-color: white;
                  top: -4px;
                  left: 50%;
                  transform: translateX(-50%);"></div>
                </div>
              </div>
                
          </div>
          <el-button type="primary" @click="item.hover = !item.hover">去了解</el-button>
        </div>
      </div>
    </div>
    <div class="flex-center" v-if="total>3">
      <el-pagination
        background
        current-page="currentPage"
        layout="prev, pager, next"
        :page-size="3"
        :total="total">
      </el-pagination>
    </div>
  </el-dialog>
</template>
<script>
  import { skusCallback,getDetail } from "@/api/estimate";
  export default {
    data() {
      return {
        currentPage:1,
        total:2,
      };
    },
    created() {
      
    },
    methods: {
      changeTabs(i){
        this.$store.state.common.showServiceMask = false;
        // this.$bus.$emit('guideChangeTabs',i);
      },
      to_page(id){
        getDetail(id).then((val2) => {
          if (val2.code == "00000" && val2.data.skuList) {
            skusCallback(val2.data.skuList[0].id).then(res=>{
              if(res.code =='00000'){
                window.open(res.data.skuCallbackUrl)
              }
            })
          }
        });
      },
    },
    beforeDestroy() {
      // clearTimeout
    },
  };
</script>
<style>
  .mask_my_popover_icon:hover .mask_my_popover{
    display: block;
  }
  .mask_my_popover{
    display: none;
  }
  .servider-box .el-dialog--center .el-dialog__body{
    overflow: visible;
  }
</style>
<style lang="less" scoped>
  .img-box{
    position: relative;
    height: 100%;
    overflow: hidden;
    /* &:hover{
      .foolter-img{
        top: 0px;
      }    
    } */
  }
  .foolter-img{
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: all .5s;
  }
  .foolter-img-css3{
    top: 0px;
  }
  .servider-box{
    .servider-c{
      padding: 20px 10px;
      padding-top: 0px;
      box-sizing: border-box;
      flex: 1;
    }
    .servider-item{
      background: #F2F3F5;
      border-radius: 8px;
      padding: 20px 0;
      img{
        width: 80px;
        height: 80px;
        border-radius: 12px;
      }
      b{
        padding-top: 16px;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: #181818;
      }
      p{
        font-size: 13px;
        line-height: 32px;
        color: #A6A6A6;
        margin-right: 5px;
      }
    }
  }
</style>