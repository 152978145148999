/*
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-01-31 11:17:48
 * @LastEditors: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @LastEditTime: 2024-04-18 13:48:44
 * @FilePath: \an-pc\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import VueSimpleVerify from 'vue-simple-verify'
import '../node_modules/vue-simple-verify/dist/vue-simple-verify.css'
import '@/assets/styles/common.less';
import '@/assets/iconfont/iconfont.css';

import plugin from './plugin';
Vue.use(plugin);
Vue.component('vue-simple-verify', VueSimpleVerify)

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this;
	},
}).$mount('#app');
