<template>
  <el-dialog :title="$store.state.common.payTitle" class="pay-box" :visible.sync="$store.state.common.showPayMask" width="560px" @close="handleClose" :before-close="handleClose">
    <div v-loading="maskLoading" class="flex-col-center">
      <div v-if="payType == 0" class="flex-col-center">
        <template v-if="showPaySelect">
          <p class="p1">请选择支付方式</p>
          <div class="flex-center payTypeCla">
            <div @click="changeChoice(0)" class="payTypeItem flex-center" style="margin-right: 20px;" :class="payChoice==0?'sel-check':''">
              <img class="payType-logo" src="https://f.auto-axc.com/pc-an/an_imgs/pai-ali.png" />
              <span>支付宝</span>
              <i class="el-icon-check"></i>
            </div>
            <div @click="changeChoice(1)" class="payTypeItem flex-center" :class="payChoice==1?'sel-check':''">
              <img class="payType-logo" src="https://f.auto-axc.com/pc-an/an_imgs/pay-weixin.png" />
              <span>微信</span>
              <i class="el-icon-check"></i>
            </div>
          </div>
        </template>
        <p class="p2">待支付<span>{{payPrice}}</span>元</p>

        <el-statistic class="daojizhi flex-center" @finish="finish" v-if="payChoice == 0 && showStatistic" format="HH:mm:ss" :value="aliTime" title="支付倒计时：" time-indices />
        <el-statistic class="daojizhi flex-center" @finish="finish" v-if="payChoice == 1 && showStatistic" format="HH:mm:ss" :value="wxTime" title="支付倒计时：" time-indices />
        
        <div v-if="$store.state.common.showPayMask" class="weixin-pay-code flex-col-center">
          <iframe v-show="payChoice == 0" id="alipayiFrame"></iframe>
          <p v-show="payChoice == 0">打开支付宝扫一扫</p>
          <div v-show="payChoice == 1" ref="weixinPay" class="wxPayBox"></div>
          <p v-show="payChoice == 1">打开微信扫一扫</p>
        </div>
      </div>
      <template v-if="payType == 1">
        <img class="payErrImg" src="https://f.auto-axc.com/pc-an/an_imgs/pay-error.png" alt="">
        <p class="p4">支付失败</p>
        <p class="p5">支付失败，请重新扫码支付获取估价结果</p>
        <el-button @click="closePay" class="mask-btn" type="primary">重新支付</el-button>
      </template>
      <template v-if="payType == 2">
        <img class="payErrImg" src="https://f.auto-axc.com/pc-an/an_imgs/pay-success.png" alt="">
        <p class="p4">支付成功</p>
        <p class="p5">请耐心等待，{{btnTextObj[$store.state.common.categoriesParentId]}}结果正在生成</p>
        <el-button @click="to_detail" class="mask-btn" type="primary">查看{{btnTextObj[$store.state.common.categoriesParentId]}}订单</el-button>
      </template>
      <!-- 法一：支付宝占坑 -->
      <!-- <div ref="aliPayBtn" v-html="$store.state.common.aliPayHtml"></div> -->

    </div>
  </el-dialog>
</template>
<script>
  
  import { pollingOrder,omsQrcodePay } from '@/api/estimate';
  import { estimateOrderDetail } from '@/api/personalCenter'
  import QRCode from 'qrcodejs2';
  var itemObj = null;
  export default {
    data() {
      return {
        payChoice:0, // 0:支付宝支付  1：微信支付
        payType:0, // 0:待支付  1:支付失败  2:支付成功
        aliTime:Date.now() + 30*60*1000, // 支付宝倒计时
        wxTime:Date.now() + 30*60*1000,  // 微信倒计时
        timeId:'',
        parmas:{},
        maskLoading:false,
        aliPayHtml:'',
        payPrice:'',
        wxQrcode:'', // 微信支付二维码链接
        showStatistic:false,
        showPaySelect:true, // 支付方式是否可选
        btnTextObj:{
          102:'估价',
          103:'检测',
          3:'维修',
          6:'出险',
          109:'电池'
        }
      };
    },
    created() {
      
    },
    mounted() {
      this.$bus.$on('payIsInfo', this.maskPayIsInfo);
    },
    methods: {
      maskPayIsInfo(parmas,item){
        this.showStatistic = false;
        this.parmas = parmas;
        this.orderNo = parmas.outTradeNo;
        this.$store.state.common.showPayMask = true;
        this.payType = 0;
        this.payChoice = 0;
        this.wxQrcode = '';
        this.aliPayHtml = '';
        this.maskLoading = false;
        this.showPaySelect = true;
        this.payPrice = parmas.amount/100; 
        console.log(parmas);
        if(parmas.paymentUrl){ // 订单页支付
          // this.showPaySelect = false;
          itemObj = item; // 支付成功后直接修改订单状态
          if(parmas.paymentUrl.indexOf('weixin://wxpay/') === 0){
            this.payChoice = 1;
            this.wxQrcode = parmas.paymentUrl;
            this.setWxPay();
          }else{
            this.payChoice = 0;
            this.aliPayHtml = parmas.paymentUrl;
            this.setAliPay();
          }
        }else{
          if(this.payChoice == 0){
            this.getAlipayIsInfo();
          }else{
            this.getWeixinPayIsInfo();
          }
        }
        
      },
      // 获取支付宝支付二维码
      getAlipayIsInfo(){
        this.maskLoading = true;
        this.showStatistic = false;
        this.parmas.channel = 1; // 支付宝支付
        omsQrcodePay(this.parmas).then(res=>{
          this.maskLoading = false;
          if(res.code == '00000'){
            this.aliPayHtml = res.data;
            this.setAliPay();
          }
        })
      },
      // 获取微信支付二维码
      getWeixinPayIsInfo(){
        this.parmas.channel = 2; // 微信支付
        this.showStatistic = false;
        this.maskLoading = true;
        omsQrcodePay(this.parmas).then(res=>{
          this.maskLoading = false;
          if(res.code == '00000'){
            this.wxQrcode = res.data;
            this.setWxPay();
          }
        })
      },
      // 生成支付宝支付
      setAliPay(){
        this.getPollingOrder();
        setTimeout(()=>{
          // 方法一：跳转页面支付
          // this.$refs['aliPayBtn'].children[0].submit();

          // 方法二：iframe嵌套支付二维码
          let o = document.querySelector('#alipayiFrame');
          let ed = document.all?o.contentWindow.document:o.contentDocument;
          ed.open();
          ed.write(this.aliPayHtml);
          ed.close();
          ed.contentEditable = true;
          ed.designMode = 'on';
          this.countdown();
        },100);
      },
      // 生成微信支付+开启微信倒计时
      setWxPay(){
        this.getPollingOrder();
        this.$nextTick(()=>{
          new QRCode(this.$refs.weixinPay,{
            text:this.wxQrcode,
            width:120,
            height:120,
            colorDark:'black',
            colorLight:'white'
          })
          this.countdown();
        })
      },
      getPollingOrder(){
        if(this.timeId){
          clearTimeout(this.timeId);
          this.timeId = '';
        }
        this.timeId =  setTimeout(()=>{
          pollingOrder(this.orderNo).then(res=>{
            if(res.code == '00000'){
              console.log(res);
              if(res.data){
                this.payType = 2;
              }else{
                if(this.$store.state.common.showPayMask){
                  this.getPollingOrder();
                }else{
                  this.timeId = '';
                }
              }
            }
          })
        },3000);
      },
      countdown(){
        this.showStatistic = true;
        if(this.payChoice == 0){ // 支付宝倒计时
          this.aliTime = Date.now() + 30*60*1000;
        }else{
          this.wxTime = Date.now() + 30*60*1000;
        }
      },
      aliSubmit(){
        
      },
      submitInfo(){

      },
      to_detail(){
        this.$store.state.common.showPayMask = false;
        if(this.showPaySelect){
          // 102:估价
          // if(this.$store.state.common.categoriesParentId && this.$store.state.common.categoriesParentId == 102){
          //   this.$router.push('/estimate/detail?orderNo='+this.orderNo) // 估价详情
          // }else{ // 103：车辆检测 102:估价 3：维修报告  6：出险  109：电池
            let orderType = null;
            if(this.$store.state.common.categoriesParentId == 103) orderType = 0; // 检测工单类型
            if(this.$store.state.common.categoriesParentId == 102) orderType = 1; // 估价工单类型
            if(this.$store.state.common.categoriesParentId == 3) orderType = 2; // 维修工单类型
            if(this.$store.state.common.categoriesParentId == 6) orderType = 3; // 出险工单类型
            if(this.$store.state.common.categoriesParentId == 109) orderType = 4; // 电池工单类型
            if(this.categoriesParentId == 102){ // 估价临时处理：轮询查询出结果后再跳详情页
              this.maskLoading = true;
              this.pollingOrderDetail(orderType,this.orderNo);
            }else{
              this.$router.push(`/personalCenter/detail?orderType=${orderType}&orderNo=${this.orderNo}`) // 工单详情 
            }
          // }
        }else{ // 订单支付
          itemObj.status = 1;
          itemObj.statusLabel = '已付款';
        }
      },
      // 轮询详情接口，查看工单是否生成
      pollingOrderDetail(type,orderNo){
        if(this.$route.path == '/estimate'){
          setTimeout(() => {
            if(type ===0){ // 检测
              // detectionOrderDetail(orderNo).then(res => {
              //   if (res.code == '00000') {
              //     this.orderData = res.data;
              //   }
              // })
            }else if(type ===1){ // 估价
              estimateOrderDetail(orderNo).then(res => {
                if (res.code == '00000' && res.data.orderStatus) {
                  this.maskLoading = false;
                  this.$router.push(`/personalCenter/detail?orderType=${type}&orderNo=${orderNo}`) // 工单详情 
                }else{
                  this.pollingOrderDetail(type,orderNo);
                }
              })
            }else if(type ===2){ // 维修
              // estimateOrderDetail(orderNo).then(res => {
              //   if (res.code == '00000') {
              //     this.orderData = res.data;
              //   }
              // })
            }else if(type ===3){ // 出险
              // getVehicleMaintenanceOrder(orderNo).then(res => {
              //   if (res.code == '00000') {
              //     this.orderData = res.data;
              //   }
              // })
            }
          }, 1000);
        }
      },
      changeChoice(i){
        this.payChoice = i;
        if(i == 1 && !this.wxQrcode){
          this.getWeixinPayIsInfo();
        }else if(i == 0 && !this.aliPayHtml){
          this.getAlipayIsInfo();
        }
      },
      closePay(){
        let dom = document.querySelector('#submitInfoId'); // 重新创建订单发起支付
        if(dom) dom.click();
      },
      finish(){
        clearTimeout(this.timeId);
        this.timeId = '';
        this.payType = 1;
      },
      handleClose(done) {
        clearTimeout(this.timeId);
        this.timeId = '';
        this.aliPayHtml = '';
        this.wxQrcode = '';

        done && done();
        // this.$confirm('确认关闭？').then(_ => {
        //   done();
        // }).catch(_ => { });
      }
    },
    beforeDestroy() {
      
    },
  };
</script>
<style>
  .daojizhi.el-statistic .head{
    margin-bottom:0px;
    color: #A6A6A6;
    font-size: 12px;
  }
  .el-statistic .con .number{
    font-size: 12px;
    color: #A6A6A6;
    font-weight: 200;
  }
</style>
<style lang="less" scoped>
  .wxPayBox{
    width: 120px;
    height: 120px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #alipayiFrame{
    width: 126px;
    height: 126px;
    border: none;
    margin-left: 4px;
    margin-top: 10px;
  }
  .weixin-pay-code{
    border: 1px solid #E7E7E7;
    border-radius: 6px;
    margin-top: 20px;
    padding: 10px 16px;
    img{
      width: 120px;
			height: 120px;
    }
    p{
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .payTypeItem{
    border: 1px solid #E7E7E7;
    border-radius: 6px;
    padding: 5px 0px;
    width: 120px;
    cursor: pointer;
    position: relative;
    .el-icon-check{
      position: absolute;
      right: 0px;
      top: 0px;
      font-size: 14px;
      width: 14px;
      height: 14px;
      background-color: #E7E7E7;
      border-radius: 0px 6px 0 6px;
      color: white;
    }
    img{
      width: 16px;
      height: 16px;
    }
    span{
      line-height: 26px;
      color: #5E5E5E;
    }
  }
  .sel-check{
      border: 1px solid #093F87;
      .el-icon-check{
        background-color: #08C188;
      }
    }
  .pay-box{
    .payErrImg{
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
    .p4{
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      line-height: 20px;
      color: #181818;
    }
    .p5{
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
      color: #A6A6A6;
    }
    .mask-btn{
      background-color: #093F87;
      border-color: #093F87;
      width: 347px;
      height: 56px;
      margin-top: 20px;
    }
    .p1{
      color: #A6A6A6;
      padding-bottom: 12px;
      font-size: 12px;
    }
    .payTypeCla{
      padding-bottom: 20px;
    }
    .payType-logo{
      width: 26px;
      height: 26px;
      margin-right: 5px;
      margin-top: -2px;
    }
    .qrcode-box{
      border: 1px solid #E7E7E7;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 20px;
    }
    .p2{
      color: #EB0045;
      line-height: 20px;
      margin-bottom: 8px;
      span{
        font-size: 24px;
        font-weight: 600;
        padding: 0 4px;
        line-height: 24px;
      }
    }
    .p3{
      font-size: 12px;
      color: #A6A6A6;
    }
  }
  
</style>