import request from "@/utils/request";

// 订单详情
export const orderDetail = (orderNo) => {
  return request({
    url: `/oms-boot/app-api/v1/orders/detail/${orderNo}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 车辆检测订单详情
export const detectionOrderDetail = (orderNo) => {
  return request({
    url: `/vehicle-boot/api/v1/vehicle/detect/order/detail/${orderNo}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 估价订单详情
export const estimateOrderDetail = (orderNo) => {
  return request({
    url: `/vehicle-boot/api/v1/vehicle/valuation/serviceOrder/orderDetail/${orderNo}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 文件上传
export const systemBootFiles = (data) => {
  return request({
    url: `system-boot/api/v1/files`,
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// OCROss识别
export const systemBootFilesOcrOss = (data) => {
  return request({
    url: `system-boot/api/v1/files/ocrOss`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 提交认证
export const submitCertification = (data) => {
  return request({
    url: `ums-boot/app-api/v1/auth`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 提交企业认证
export const submitCompanyCertification = (data) => {
  return request({
    url: `ums-boot/app-api/v1/auth/company`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * @Date: 2024-03-21 21:12:33
 * @description: 获取用户信息接口
 * @return {*}
 */
export const getUserInfo = () => {
  return request({
    url: `ums-boot/app-api/v1/members/me`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * 收藏车辆列表
 */
export const queryMyFavoriteList = (page = 1, pageSize = 5) => {
  return request({
    url: `vehicle-boot/app-api/v1/usedCar/queryMyFavoriteList?pageNum=${page}&pageSize=${pageSize}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 获取用户信息接口 */
export const getAuthInfo = () => {
  return request({
    url: `ums-boot/app-api/v1/auth/info`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 订单列表 */
export const getOrderList = (data) => {
  return request({
    url: `oms-boot/app-api/v1/orders`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 车辆检测列表 */
export const getDetectOrderList = (data) => {
  return request({
    url: `vehicle-boot/api/v1/vehicle/detect/order/list`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 维保工单 */
export const getVehicleMaintenanceOrderList = (data) => {
  return request({
    url: `vehicle-boot/api/v1/vehicle/maintenance/order/list`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 车辆估价 */
export const getValuationServiceOrderList = (data) => {
  return request({
    url: `vehicle-boot/api/v1/vehicle/valuation/serviceOrder/list`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};


/** 取消检测工单 */
export const getOrderCancel = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehicle/detect/cancel`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};


/**
 * @description: 维修报告工单详情
 * @param {*} data
 * @return {*}
 */
export const getVehicleMaintenanceOrder = (orderSn) => {
  return request({
    url: `vehicle-boot/api/v1/vehicle/maintenance/order/detail/${orderSn}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * @description: 维修报告详情
 * @param {*} data
 * @return {*}
 */
export const getMaintenanceDetail = (orderSn) => {
  return request({
    url: `/vehicle-boot/api/v1/vehicle/maintenance/repair/report/${orderSn}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
