<template>
  <div class="login-header" :class="isMobile?'mobileCla':''">
    <div class="container flex-space-between">
      <div class="flex-start">
        <i v-if="$route.path !='/login'" @click="changeIsContract" :class="isContract?'rotateAS':''" class="el-icon-s-unfold asdvs"></i>
        <!-- <h1 class="logo"><a href=""></a></h1> -->
        <img @click="to_home" style="width: 156px;cursor: pointer;" 
          src="https://f.auto-axc.com/images/admin/new_logo.png" alt="">
        <el-cascader
          v-if="$route.path !='/login'"
          v-model="cascaderVal"
          :props="cascaderProps"
          :show-all-levels = 'false'
          @change="handleChange"
		      class="nav-casc"
        >
        </el-cascader>
      </div>
      <div class="flex-start">
  
        <el-popover
          placement="top"
          width="200"
          trigger="hover"
          v-model="visible">
          <div class="flex-col-center qrcodeMask">
            <p>扫码打开安信查小程序</p>
            <img src="https://f.auto-axc.com/images/mini-qrcode.jpg">
          </div>
          <div slot="reference" style="margin-right: 30px">
            <i class="iconfont icon-ac-lujing" style="font-size: 14px;margin-right: 5px;"></i>
            <span style="color: #4b4b4b">小程序</span>
          </div>
        </el-popover>
        <template v-if="$route.path !='/login'">
          <template v-if="$store.state.user.token">
            <i class="iconfont icon-ac-yonghu" style="font-size: 16px;margin-right: 5px;color:#4B4B4B"></i>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{userInfo && userInfo.nickName}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">个人中心</el-dropdown-item>
                <el-dropdown-item command="1">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <el-button @click="to_login" type="primary" class="login-btn" size="small">登录</el-button>
            <el-button @click="to_login" size="small" style="margin-left: 12px; color: #093f87; border-color: #093f87">注册</el-button>
          </template>
        </template>
        
        
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo,removeUserInfo, removeToken } from '@/utils/userInfo';
import { getArea } from '@/api/common';

import {logout} from '@/api/home';
export default {
  name: "appNav",
  data() {
    return {
      cascaderProps:{
        lazy: true,
        lazyLoad (node, resolve) {
          const { level } = node;
          getArea(node.value?node.value:0).then(res=>{
            if(res.code == '00000'){
              let list = [];
              res.data.forEach(item=>{
                list.push({
                  value: item.id,
                  label: item.name,
                  leaf: level >= 1 // 展示多少级别
                })
              })
              resolve(list);
            }
          })
        }
      },
      cascaderVal: [],
      visible: false,
      isContract:false,
      userInfo:{},
      isMobile:false, // 是否为移动端
    };
  },
  created() {
    let arr = localStorage.getItem('headerCityData');
    if(!!arr){
      this.cascaderVal = JSON.parse(arr);
    }else{
      localStorage.setItem('headerCityData',JSON.stringify(this.cascaderVal))
    }
    this.userInfo = getUserInfo();
  },
  mounted() {
    this.isMobile = this.isMobileInfo();
  },  
  methods: {
    //定义一个函数判断是手机端还是pc端
    isMobileInfo(){
      if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true; // 移动端
      }else{
        return false; // PC端
      }
    },
    to_home(){
      let dom = document.querySelector('body');
      dom.style.overflow = 'auto';
      this.$router.push('/home');
    },
    to_login(){
      this.$router.push('/login');
    },
    handleCommand(val){
      console.log(val);
      if(val == '0'){
        // this.$router.push('/personalCenter');
        let routeData = this.$router.resolve({
          path:'/personalCenter'
        });
        window.open(routeData.href, '_blank');

      }else if(val == '1'){
        logout().then(res=>{
          if(res.code =='00000'){
            removeUserInfo(); // 清空本地存储
            removeToken(); // 清空本地token
            this.$store.commit('user/loginOut');
            this.$router.push('/home');
          }
        })
      }
    },
    changeIsContract() {
      this.isContract = !this.isContract;
      this.$bus.$emit('emitChangeIsContract',this.isContract);
    },
    handleChange(val){
      this.$bus.$emit('changeHeaderCity',val);
      localStorage.setItem('headerCityData',JSON.stringify(val))
    }
  },
};
</script>
<style>
	.nav-casc .el-input__inner{
		border: none;
		width: 100px;
		margin-left: 10px;
	}
	.el-popper .el-cascader-node.in-active-path{
		color: #093F87;
	}
</style>

<style lang="less" scoped>
  .mobileCla{
    max-width: 1600px;
    min-width: 1450px;
  }
  .rotateAS{
    transform: rotate(180deg);
  }
  .qrcodeMask{
    p{
      padding-top: 16px;
    }
    img{
      width: 160px;
      height: 160px;
      margin-top: 10px;
    }
  }
.asdvs {
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  transition: transform .2s;
}
.flex-start .login-btn {
  background-color: #093f87;
  border-color: #093f87;
}
.login-header {
  background: #fff;
  border-bottom: 1px solid #e4e4e4;
  height: 64px;
  position: sticky;
  top: 0px;
  z-index: 11;
  .container {
    padding: 0 20px;
    height: 100%;
    background-color: white;
    .logo {
      width: 70px;
      a {
        display: block;
        /* height: 44px; */
        height: 60px;
        width: 100%;
        text-indent: -9999px;
        background: url(https://f.auto-axc.com/pc-an/an_imgs/logo.png) no-repeat 100% 100%;
      }
    }
    h3 {
      flex: 1;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 38px;
      margin-left: 20px;
      color: #666;
    }
    .entry {
      width: 120px;
      margin-bottom: 38px;
      font-size: 16px;
      i {
        font-size: 14px;
        color: #27ba9b;
        letter-spacing: -5px;
      }
    }
  }
}
</style>
