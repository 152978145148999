import { render, staticRenderFns } from "./mask-service-provider.vue?vue&type=template&id=cf98f8c0&scoped=true"
import script from "./mask-service-provider.vue?vue&type=script&lang=js"
export * from "./mask-service-provider.vue?vue&type=script&lang=js"
import style0 from "./mask-service-provider.vue?vue&type=style&index=0&id=cf98f8c0&prod&lang=css"
import style1 from "./mask-service-provider.vue?vue&type=style&index=1&id=cf98f8c0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf98f8c0",
  null
  
)

export default component.exports