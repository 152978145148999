<template>
  <el-dialog custom-class="el-dialog-s" append-to-body class="guide-box" :visible.sync="$store.state.common.showGuideMask" width="1200px">
    <div class="flex-col-center">
      <h5 v-if="$store.state.common.guideMaskShowType==0">安行网车辆服务产品介绍，国资平台安全可靠</h5>
      <h5 v-if="$store.state.common.guideMaskShowType==1">安行网车辆估价服务，国资平台安全可靠</h5>
      <div v-if="$store.state.common.guideMaskShowType==0" class="flex-center">
        <div v-for="(item,i) in vehicleServiceList" :key="i" class="flex-col-center guide-item">
          <img :src="item.icon" alt="">
          <h6>{{item.title}}</h6>
          <p>{{item.desc}}</p>
          <el-button @click="changeTabs(i)" class="guide-btn" type="primary">去下单</el-button>
          <span>由合作机构提供</span>
        </div>
      </div>
      <div v-if="$store.state.common.guideMaskShowType==1" class="flex-center" style="width: 100%;">
        <div class="flex-col-center guide-item" style="width: 80%;">
          <img src="https://f.auto-axc.com/pc-an/an_imgs/guide-icon-5.png" alt="">
          <h6>车辆估价</h6>
          <p>接入全网第三方机构数据，提供全面的车辆估价报告，准确评估车辆价值</p>
          <el-button @click="changeTabs()" class="guide-btn" type="primary">去下单</el-button>
          <span>由合作机构提供</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        vehicleServiceList:[
          {
            icon:'https://f.auto-axc.com/pc-an/an_imgs/guide-icon-1.png',
            title:'车辆检测',
            desc:'全网第三方专业机构合作，权威检测，确保车况透明、可靠',
          },
          {
            icon:'https://f.auto-axc.com/pc-an/an_imgs/guide-icon-2.png',
            title:'维修报告',
            desc:'提供详实的车辆维修报告，与专业机构团队合作，精准评估车况问题',
          },
          {
            icon:'https://f.auto-axc.com/pc-an/an_imgs/guide-icon-3.png',
            title:'事故报告',
            desc:'专业保险机构数据，提供全面的车辆出险报告，准确记录事故信息',
          },
          {
            icon:'https://f.auto-axc.com/pc-an/an_imgs/guide-icon-4.png',
            title:'电池报告',
            desc:'依托专业团队与先进技术，提供精准的电池健康状态、充放电性能等报告',
          },
        ],
      };
    },
    created() {
      
    },
    methods: {
      changeTabs(i){
        this.$store.state.common.showGuideMask = false;
        this.$bus.$emit('guideChangeTabs',i);
      },
      changeChoice(i){
        this.payChoice = i;
      },
    },
    beforeDestroy() {
      // clearTimeout
    },
  };
</script>
<style>
  .guide-box .el-dialog{
    background-image: url('https://f.auto-axc.com/pc-an/an_imgs/guide-bg.png');
    background-size: 100% 100%;
  }
</style>
<style lang="less" scoped>
  .guide-box{
    h5{
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      color: #181818;
    }
    .guide-item{
      padding: 24px 24px 34px 24px;
      background: #FCFDFF;
      border: 1px solid ;
      border-image: linear-gradient(143deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) 1;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);
      margin-top: 60px;
      margin-bottom: 40px;
      img{
        width: 80px;
        height: 80px;
      }
      h6{
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        color: #181818;
        padding-top: 24px;
      }
      p{
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #8D8E99;
        margin-bottom: 24px;
        width: 197px;
      }
      .guide-btn{
        width: 105px;
        background: #093F87;
        border-color: #093F87;
      }
      span{
        font-size: 12px;
        line-height: 11px;
        color: #A6A6A6;
        margin-top: 22px;
      }
    }
  }
</style>