<template>
    <div class="menu-box1">
        <div v-for="(item,i) in navList" :key="i" class="menu-item flex-space-between" 
            @click="to_path(item.paths[0])" 
            :class="[item.paths.includes($route.path)?'sel-menu':'',isShowNav?'menu-item-open':'menu-item-contract']">
            <div class="flex-start">
                <i :class="item.icon" class="iconfont"></i>
                <p class="menu-item-p">{{item.name}}</p>
            </div>
            <span v-show="!isShowNav && item.hoverList"></span>
            <div v-if="item.hoverList" class="fooler-box">
                <p @click.stop="to_path(val.path)" v-for="(val,index) in item.hoverList" :key="index">{{val.title}}</p>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShowNav:false,
            headerIsShowNav:'',
            navList:[
                {
                    name:'首页',
                    icon:'icon-ac-a-lujing1',
                    paths:['/','/home']
                },
                {
                    name:'资讯',
                    icon:'icon-ac-rongqi',
                    paths:['/information/tabs1','/information/tabs2','/information/tabs3','/information/tabs4','/information/tabs2/detail'],
                    hoverList:[
                        {
                            title:'平台发布',
                            path:'/information/tabs1',
                        },
                        {
                            title:'测评通告',
                            path:'/information/tabs2',
                        },
                        {
                            title:'结果发布',
                            path:'/information/tabs3',
                        },
                        {
                            title:'试验通知',
                            path:'/information/tabs4',
                        }
                    ]
                },
                {
                    name:'汽车测评',
                    icon:'icon-ac-a-rongqi1',
                    paths:['/appraisal/tabs1','/appraisal/tabs2','/appraisal/tabs2/detail','/appraisal/detail'],
                    hoverList:[
                        {
                            title:'测评专区',
                            path:'/appraisal/tabs1',
                        },
                        {
                            title:'消费者专区',
                            path:'/appraisal/tabs2',
                        }
                    ]
                },
                // {
                //     name:'车辆服务',
                //     icon:'icon-ac-a-rongqi2',
                //     paths:['/vehicleService/detection','/vehicleService/repair','/vehicleService/insurance','/vehicleService/battery'],
                //     hoverList:[
                //         {
                //             title:'车辆检测',
                //             path:'/vehicleService/detection',
                //         },
                //         {
                //             title:'维修报告',
                //             path:'/vehicleService/repair',
                //         },
                //         {
                //             title:'出险报告',
                //             path:'/vehicleService/insurance',
                //         },
                //         {
                //             title:'电池报告',
                //             path:'/vehicleService/battery',
                //         }
                //     ]
                // },
                {
                    name:'推荐服务',
                    paths:['/referralService'],
                    icon:'icon-ac-a-rongqi2',
                },
                {
                    name:'二手车展厅',
                    icon:'icon-ac-a-rongqi3',
                    paths:['/usedCar/tabs1','/usedCar/tabs2'],
                    hoverList:[
                        {
                            title:'二手车展厅',
                            path:'/usedCar/tabs1',
                        },
                        {
                            title:'保值排行',
                            path:'/usedCar/tabs2',
                        },
                    ]
                },
                // {
                //     name:'卖车估价',
                //     icon:'icon-ac-a-rongqi4',
                //     paths:['/estimate'],
                // },
                // {
                //     name:'数据平台',
                //     icon:'icon-ac-a-rongqi5',
                //     paths:[],
                // },
                {
                    name:'溯源查证',
                    paths:['/traceability'],
                    icon:'icon-ac-a-rongqi6',
                },
                {
                    name:'关于我们',
                    icon:'icon-ac-a-rongqi7',
                    paths:['/aboutUs/synopsis','/aboutUs/introduce','/aboutUs/structure','/aboutUs/contact'],
                    // hoverList:[
                    //     {
                    //         title:'公司简介',
                    //         path:'/aboutUs/synopsis',
                    //     },
                    //     {
                    //         title:'服务介绍',
                    //         path:'/aboutUs/introduce',
                    //     },
                    //     {
                    //         title:'组织架构',
                    //         path:'/aboutUs/structure',
                    //     },
                    //     // {
                    //     //     title:'发展历程',
                    //     //     path:'',
                    //     // },
                    //     {
                    //         title:'联系我们',
                    //         path:'/aboutUs/contact',
                    //     },
                    // ]
                },
            ],
        }
    },
    created(){
        
    },
    mounted() {
        document.body.addEventListener('scroll', this.handleScroll)
        this.$bus.$on('emitChangeIsContract', this.setEmitChangeIsContract);
    },
    methods:{
        setEmitChangeIsContract(val){
            this.headerIsShowNav = val;
            this.isShowNav = val;
        },
        to_path(url){
            this.$router.push(url);
        },
        // 获取页面滚动距离
        handleScroll () {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if(this.headerIsShowNav ===''){
                this.isShowNav = !!(scrollTop>200);
            }
        }
    },
    destroyed() {
        window.removeEventListener('scroll',this.handleScroll);
    },
}
</script>
<style lang="less" scoped>
    .menu-box1{
        width:200px;
        height: 700px;
        padding-top: 20px;
        position: sticky;
        top: 64px;
        z-index: 10;
        .menu-item{
            position: relative;
            transition: all .25s;
            &:hover{
                background: rgba(9, 63, 135, 0.05);
                color: #093F87;
                span{
                    border-color: #093F87;
                }
                .fooler-box{
                    display: block;
                }
            }
            cursor: pointer;
            padding: 10px 0;
            border-radius: 6px;
            margin: 0 24px 10px 24px;
            box-sizing: border-box;
            position: relative;
            p{
                margin-top: 12px;
                font-size: 14px;
            }
            span{
                width: 8px;
                height: 8px;
                border-top: 1px solid #333;
                border-right: 1px solid #333;
                transform: rotate(45deg);
                margin-right: 10px;
            }
            .fooler-box{
                position: absolute;
                left: 72px;
                top: 0px;
                background-color: white;
                border-radius: 6px;
                width: 140px;
                padding: 6px;
                display: none;
                z-index: 100;
                box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),0px 8px 10px 1px rgba(0, 0, 0, 0.06),0px 5px 5px -3px rgba(0, 0, 0, 0.1);
                &:hover{
                    display: block;
                }
                p{
                    height: 28px;
                    line-height: 28px;
                    margin-bottom: 6px;
                    margin-left: 0px;
                    padding-left: 8px;
                    margin-top: 0px;
                    box-sizing: border-box;
                    color: rgba(0, 0, 0, 0.9);
                    &:nth-last-child(1){
                        margin-bottom: 0px;
                    }
                    &:hover{
                        background: rgba(9, 63, 135, 0.05);
                    }
                }
            }
        }
        
        .menu-item-contract{
            width: 132px;
            height: 40px;
            i{
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
            .menu-item-p{
                position: absolute;
                left: 40px;
                margin-top: 0px;
                white-space:nowrap;
                line-height: 18px;
            }
            .fooler-box{
                left: 132px;
            }
        }
        .menu-item-open{
            width: 72px;
            height: 72px;
            margin-left: 70px;
            i{
                position: absolute;
                left: 50%;
                top: 5px;
                transform: translateX(-50%);
            }
            .menu-item-p{
                position: absolute;
                left: 0px;
                width: 100%;
                margin-top: 20px;
                text-align: center;
            }
        }

    }
    .menu-item.sel-menu{
        background-color: #093F87;
        color: white;
        span{
            border-color: white;
        }
    }
</style>