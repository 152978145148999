/*
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-01-31 11:17:48
 * @LastEditors: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @LastEditTime: 2024-04-18 14:55:25
 * @FilePath: \an-pc\src\store\modules\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getToken, getUserInfo } from '@/utils/userInfo';
export default {
	// 开启命名空间
	namespaced: true,
	state: {
		// 用户信息
		userInfo: getUserInfo() || {},
		token: getToken(),
		// 用户地址信息
		userAddress: [],
	},
	mutations: {
		// 设置token
		setToken(state, val) {
			state.token = val;
		},
		// 设置用户信息
		setUserInfo(state, val) {
			state.userInfo = val;
		},
		// 移除token | 用户信息
		loginOut(state) {
			state.token = null;
			state.userInfo = {};
			state.userAddress = [];
		},
		GETUSERADDRESS(state, data) {
			state.userAddress = data;
		},
	},
	actions: {
	},
	getters: {},
};
