import request from '@/utils/request';


// 获取车辆品牌
export const vehicleBrands = (data) =>{
	return request({
		url:`vehicle-boot/api/v1/vehicle/brands${data?'/'+data:''}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
		},
	})
}
// 获取车辆车系
export const vehicleSeries = brandId =>{
	return request({
		url:`/vehicle-boot/api/v1/vehicle/series/${brandId}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
		},
	})
}

// 获取车辆车型
export const vehicleModels = seriesId =>{
	return request({
		url:`/vehicle-boot/api/v1/vehicle/models/${seriesId}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
		},
	})
}

// 获取省市区
export const getArea = pid =>{
	return request({
		url:`/vehicle-boot/api/v1/area/list/${pid}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
		},
	})
}


// 基础估价接口
export const getUsedCarPrice = data =>{
	return request({
		url:`/service/getUsedCarPrice?modelId=${data.modelId}&regDate=${data.regDate}&mile=${data.mile}&zone=${data.zone}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 留资接口
export const addClue = data =>{
	return request({
		url:`/sms-boot/app-api/v1/crm/addClue`,
		method: 'post',
		data,
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 获取车辆检测报告
export const report = data =>{
	let str = '';
	if(data.reportCode) str = `reportCode=${data.reportCode}`;
	if(data.orderSn) str = `orderSn=${data.orderSn}`;
	return request({
		url:`/vehicle-boot/api/v1/vehicle/detect/report?${str}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
		},
	})
}

// 获取城市检测网点
export const outlets = (data) =>{
	return request({
		url:`/vehicle-boot/api/v1/vehicle/detect/outlets?cityCode=${data.cityCode}&skuId=${data.skuId}`,
		method: 'get',
		headers: { 
			"Content-Type": "application/x-www-form-urlencoded",
		},
	})
}

// 获取检测开通城市
export const openCity = (id) =>{
	return request({
		url:`/vehicle-boot/api/v1/vehicle/detect/openCity?provinceCode=${id}`,
		method: 'get',
		headers: { 
			"Content-Type": "application/x-www-form-urlencoded",
		},
	})
}

// 根据vin获取车型
export const vinGetBrandId = (id) =>{
	return request({
		url:`/vehicle-boot/api/v1/vehicle/model/${id}`,
		method: 'get',
		headers: { 
			"Content-Type": "application/x-www-form-urlencoded",
		},
	})
}

// 签名接口
export const addSignature = data =>{
	let formdata = new FormData();
	formdata.append('url',data.url)
	return request({
		url:`/ums-boot/app-api/v1/auth/addSignature`,
		method: 'post',
		data:formdata,
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}


