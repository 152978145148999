/*
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-01-31 11:17:48
 * @LastEditors: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @LastEditTime: 2024-04-18 14:55:38
 * @FilePath: \an-pc\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import common from './modules/common';
Vue.use(Vuex);
export default new Vuex.Store({
	modules: {
		user,
		common
	},
});
