<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-01-31 11:17:48
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-07-07 22:13:40
 * @FilePath: \an-pc\src\components\app-footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<footer class="app-footer">
		<div class="footer-l">
			<img src="https://f.auto-axc.com/images/admin/new_logo.png" alt="">
			<div class="flex-space-between">
				<div>
					<ul class="flex-start">
						<li @click="to_page('/information/tabs1')">资讯</li>
						<li @click="to_page('/traceability')">溯源查证</li>
						<li @click="to_page('/referralService')">推荐服务</li>
						<li @click="to_page('/usedCar/tabs1')">二手车展厅</li>
						<li @click="to_page('/aboutUs/synopsis')">关于我们</li>
					</ul>
					<p>公司网址：  www.auto-axc.com</p>
					<p>Copyright © 中汽研汽车科技发展(天津)有限公司   津ICP备2024014600号-4 </p>
					<!-- <p>Copyright © 中汽中心汽车测评   京ICP备13012136号    京公网安备11010802013095号</p> -->
					<!-- <p>津ICP备2020010153号-4</p> -->
				</div>
				<div class="footer-r">
					<img src="https://f.auto-axc.com/images/mini-qrcode.jpg" alt="">
					<p>打开微信扫一扫<br>
						使用小程序体验</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'AppFooter',
		methods: {
			to_page(path){
				this.$router.push({
						path,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	@import '@/assets/styles/variables.less';

	.app-footer {
		padding: 100px 0px;

		.footer-l {
			img {
				width: 184px;
			}

			li {
				margin-right: 32px;
				font-size: 14px;
				color: rgba(0, 0, 0, 0.9);
				margin-bottom: 12px;
				font-weight: 900;
				cursor: pointer;
			}

			p {
				color: rgba(0, 0, 0, 0.9);
				font-size: 14px;
				margin-top: 12px;
			}
		}

		.footer-r {
			img {
				width: 102px;
				height: 102px;
				border-radius: 6px;
			}

			p {
				color: rgba(0, 0, 0, 0.9);
			}
		}
	}
</style>