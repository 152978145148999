import request from "@/utils/request";

// 热卖二手车列表
// export const queryHotSaleList = data =>{
// 	return request({
// 		url:`vehicle-boot/api/v1/usedCar/queryHotSaleList`,
// 		method: 'post',
// 		data,
// 		headers: {
// 			'Content-Type': 'application/json',
// 			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
// 		},
// 	})
// }

// 今日推荐车辆列表
export const queryRecommendedList = (data) => {
  return request({
    url: `/vehicle-boot/app-api/v1/usedCar/queryRecommendedList`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 二手车汽车类型
export const getAdvertisementList = (type) => {
  return request({
    url: `/system-boot/api/v1/activity/getAdvertisementList?type=2&activityType=${type}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 首页查询通告列表接口
export const getNoticeInfo = () => {
  return request({
    url: `/system-boot/api/v1/notice/getNoticeInfo`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 首页查询通告列表接口
export const logout = () => {
  return request({
    url: `/system-boot/api/v1/users/logout`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};


