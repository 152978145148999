/*
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-01-31 11:17:48
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-04-14 22:48:16
 * @FilePath: \an-pc\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { removeUserInfo, removeToken } from '@/utils/userInfo';
import { Message } from 'element-ui';

import NProgress from "nprogress";
import "nprogress/nprogress.css";

// 创建axios实例
const instance = axios.create({
	// 基础路径
	// baseURL: 'https://apipc-xiaotuxian-front.itheima.net/', // 上线请求地址
	// baseURL: 'http://pcapi-xiaotuxian-front-devtest.itheima.net/',
	baseURL: process.env.VUE_APP_BASE_API,
	// baseURL: '/',
	// 超时时间
	timeout: 60000,
});

const skipShowMsg = ['ums-boot/app-api/v1/auth','ums-boot/app-api/v1/auth/company'];

// 请求拦截器
instance.interceptors.request.use(
	config => {
		// 如果有token进行头部携带 | 没有则后续操作通知用户登录
		if (store.state.user.token) {
			config.headers.Authorization = `Bearer ${store.state.user.token}`;
		}
		NProgress.start();
		return config;
	},
	error => {
		console.log(error);
	}
);

// 响应拦截器
instance.interceptors.response.use(
	response => {
		NProgress.done();
		if(response.data.code != '00000'){
			// this.$message({
			// 	message: response.data.msg,
			// 	type: 'warning'
			// });
		}
		return response.data;
	},
	error => {
		NProgress.done();
		console.log('error=',error.config.url);

		// 处理token失效
		if (error.response && error.response.status === 401) {
			removeUserInfo(); // 清空本地存储
			removeToken(); // 清空本地token
			store.commit('user/loginOut');
			router.push('/login');
			return error.response;
		}else if(error.response.status === 400){
			if(!skipShowMsg.includes(error.config.url)) Message.error(error.response.data.msg);
			return error.response.data;
		}
		return Promise.reject(error);
	}
);

export default instance;
