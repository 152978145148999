/*
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-01-31 11:17:48
 * @LastEditors: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @LastEditTime: 2024-07-10 11:34:08
 * @FilePath: \an-pc\src\plugin\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// elementui自定义主题色 #093F87
import '@/assets/styles/elementui-theme.css';
import VueLazyload from 'vue-lazyload';

export default {
	install(Vue) {
		// Vue.use(ElementUI);
		// 配置项
		Vue.use(VueLazyload, {
			preLoad: 1.3,
			error: require('../assets/images/200.png'),
			loading: require('../assets/images/loading.png'),
			attempt: 1,
		});
	},
};
