<template>
  <el-dialog :title="$store.state.common.mobileTitle" class="mobile-box" :visible.sync="$store.state.common.showMobileMask" width="560px" :before-close="handleClose">
    <div class="flex-center">
      <div class="flex-col-center" style="width: 400px;">
        
        <template v-if="$store.state.user.token">
          <template v-if="$store.state.common.boxType == '0'">
            <p class="inp-box">{{$store.state.common.provinceObj && $store.state.common.provinceObj.cityName}}</p>
            <p class="inp-box">
              {{($store.state.common.provinceObj && $store.state.common.provinceObj.phone)?$store.state.common.provinceObj.phone:$store.state.user.userInfo.mobile}}</p>
            <!-- <div class="flex-space-between code-box">
              <el-input v-model="codeVal" style="width: 264px;" type="number" placeholder="请输入验证码"></el-input>
              <el-button @click="requeryCode" style="width: 120px;" class="code-btn">{{outTime?`已发送(${outTime}s)`+'':'发送验证码'}}</el-button>
              <div class="flex-space-between code-desc">
                <div v-if="succMsg" class="flex-start code-desc-l">
                  <i class="el-icon-success"></i>
                  <span>验证码已发送</span>
                </div>
                <div v-if="errMsg" class="flex-start code-desc-l2">
                  <i class="el-icon-warning"></i>
                  <span>{{errMsg}}</span>
                </div>
                <div v-if="true" class="code-desc-r flex-start">
                  <p>短信收不到，试试</p>
                  <span>语音验证码</span>
                </div>
              </div>
            </div> -->
            <el-button style="margin-top:12px" @click="homeBtnClick" class="submit-btn" type="primary">预约卖车</el-button>
            <div class="flex-start-2 qianshu" style="width: 400px;">
              <el-checkbox v-model="checked" style="margin-right: 16px;"> </el-checkbox>
              <p style="color: #A6A6A6;">已阅读并同意
                <span @click="openAgreement('https://f.auto-axc.com/pc-an/static/new_information.pdf')">《个人信息保护声明》</span>及
                <span @click="openAgreement('https://f.auto-axc.com/pc-an/static/new_privacy.pdf')">《用户隐私政策》</span>
              </p>
            </div>
          </template>
          <template v-if="$store.state.common.boxType == '2'">
            <div class="flex-col-center">
              <i class="el-icon-success" style="font-size: 70px;color: #2BA471;"></i>
              <h6 class="title-p1">{{$store.state.common.successTitle}}</h6>
              <p style="font-size: 16px;color: #4B4B4B;">{{$store.state.common.successDesc}}</p>
            </div>
          </template>

          <template v-if="$store.state.common.boxType == '1'">
            <p class="p1">{{$store.state.common.usedCarData.modelName}}</p>
            <div class="flex-start p2">
              <p v-if="$store.state.common.usedCarData.recordDate">{{$store.state.common.usedCarData.recordDate.slice(0,4)}}年上牌</p>
              <p>{{$store.state.common.usedCarData.mileage}}万公里</p>
              <p>{{$store.state.common.usedCarData.registrationRegionCityName}}</p>
            </div>
            <p class="inp-box">{{$store.state.user.userInfo.mobile}}</p>
            <!-- <el-input v-model="mobileVal" @blur="mobileBlur" style="width: 100%;margin-bottom: 20px;" type="number" maxlength="11" placeholder="请输入手机号码"></el-input> -->
            <!-- <div class="flex-space-between code-box">
              <el-input v-model="codeVal" style="width: 264px;" type="number" placeholder="请输入验证码"></el-input>
              <el-button @click="requeryCode" style="width: 120px;" class="code-btn">{{outTime?`已发送(${outTime}s)`+'':'发送验证码'}}</el-button>
              <div class="flex-space-between code-desc">
                <div v-if="succMsg" class="flex-start code-desc-l">
                  <i class="el-icon-success"></i>
                  <span>验证码已发送</span>
                </div>
                <div v-if="errMsg" class="flex-start code-desc-l2">
                  <i class="el-icon-warning"></i>
                  <span>{{errMsg}}</span>
                </div>
                <div v-if="true" class="code-desc-r flex-start">
                  <p>短信收不到，试试</p>
                  <span>语音验证码</span>
                </div>
              </div>
            </div> -->
            <el-button @click="submitInfo" class="submit-btn"  type="primary">立即询价</el-button>
            <div class="flex-start qianshu">
              <el-checkbox v-model="checked1">同意</el-checkbox>
              <span @click="openAgreement('https://f.auto-axc.com/pc-an/static/new_agreement.pdf')">《用户协议》</span>
            </div>
          </template>
        </template>
        <template v-else>
          <p class="login-p1">请先完成登录</p>
          <p class="login-p2">登录后获取车辆信息</p>
          <el-button @click="loginClick" class="submit-btn"  type="primary">立即登录</el-button>
        </template>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { login,reqSmsCode,reqMobileLogin } from '@/api/login';
  import { addClue } from '@/api/common';
  
  export default {
    data() {
      return {
        mobileVal:'',
        codeVal:'',
        checked:false,
        checked1:false,
        outTime:0,
        errMsg:'',
        succMsg:'',
        codeType:0, // 0:未验证发送 1:验证发送 2:发送倒计时 3:重新发送
      };
    },
    methods: {
      openAgreement(URL){
        window.open(URL)
      },
      loginClick(){
        this.$store.state.common.showMobileMask = false;
        localStorage.setItem('maskBoxType',this.$store.state.common.boxType)
        this.$router.push('/login');
      },
      homeBtnClick(){
        if(!this.checked){
          this.$message({
            message: '请阅读并同意《个人信息保护说明》',
            type: 'warning'
          });
          return ;
        }
        
        let parmas = {
          intentionType:'2',
          phone:this.$store.state.user.userInfo.mobile,
        }
        parmas = {...parmas,...this.$store.state.common.provinceObj}
        addClue(parmas).then(res=>{
          if(res){
            this.$store.state.common.boxType = '2';
            this.$store.state.common.successTitle = '预约成功';
            this.$store.state.common.successDesc = '平台将提供1-3家二手车/服务商为您提供报价服务';
          }
        })
      },
      mobileBlur(){
        let regu = /^1[3-9][0-9]{9}$/;
        if(!regu.test(this.mobileVal)){
          this.errMsg = '手机号格式不对'
        }else{
          this.errMsg = '';
        }
      },
      requeryCode(){
        if(this.mobileVal.length == 11){
          if(this.outTime ==0){
            reqSmsCode({phoneNumber:this.mobileVal}).then(res=>{
              if(res.code == '00000'){
                this.succMsg = '验证码已发送';
                this.outTime = 60;
                this.timeValId =  setInterval(()=>{
                  this.outTime = this.outTime -1;
                  if(this.outTime == 0){
                    clearInterval(this.timeValId);
                    this.codeType = 3;
                  }
                },1000)
              }else{
                this.$message.error(res.msg);
              }
            })
          }
        }else{
          this.errMsg = '请完善手机号'
        }
		  },
      submitInfo(){
        if(!this.checked1){
          this.$message({
            message: '请阅读并同意《用户协议》',
            type: 'warning'
          });
          return ;
        }
        let {brandId,brandName,seriesId,seriesName,modelId,modelName} = this.$store.state.common.usedCarData;
        let parmas = {
          intentionType:'1',
          phone:this.$store.state.user.userInfo.mobile,
          vehicleId:this.$route.query.vehicleId,
          brandCode:brandId,
          brandName,
          seriesCode:seriesId,
          seriesName,
          modelCode:modelId,
          modelName,
          actionCode:1
        }
        addClue(parmas).then(res=>{
          if(res){
            this.$store.state.common.boxType = '2';
            this.$store.state.common.successTitle = '询价成功';
            this.$store.state.common.successDesc = '车辆专家稍后会电话联系您，请耐心等待。';
          }
        })
      },
      handleClose(done) {
        done();
        // this.$confirm('确认关闭？').then(_ => {
        //   done();
        // }).catch(_ => { });
      }
    }
  };
</script>
<style lang="less" scoped>
  .title-p1{
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: #181818;
    padding: 20px 0;
  }
  .inp-box{
    height: 56px;
    line-height: 56px;
    padding: 0px 20px;
    box-sizing: border-box;
    background: #F2F3F5;
    width: 100%;
    margin-bottom: 20px;
  }
  .login-p1{
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: #181818;
  }
  .login-p2{
    margin-top: 12px;
    line-height: 24px;
    color: #5E5E5E;
  }
  .login-btn{
    margin-top: 50px;
    width: 100%;
    height: 56px;
    background-color: #093F87;
  }
  .mobile-box{
    .p1{
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: #181818;
      margin-bottom: 12px;
      width: 100%;
      text-align: left;
    }
    .p2{
      width: 100%;
      padding-bottom: 32px;
      p{
        font-size: 16px;
        line-height: 17px;
        color: #5E5E66;
        border-right: 1px solid #E7E7E7;
        padding:0 12px;
        &:nth-last-child(1){
          border-right: none;
        }
        &:nth-child(1){
          padding-left: 0px;
        }
      }
    }
    .code-box{
      margin-bottom: 20px;
      width: 100%;
      position: relative;
      .code-btn{
        color: #093F87;
        border-color: #093F87;
      }
      .code-desc{
        position: absolute;
        width: 100%;
        bottom: -40px;
        .code-desc-l{
          i{
            font-size: 20px;
            color: #24C875;
          }
          span{
            margin-left: 8px;
            color: #24C875;
            font-size: 16px;
          }
        }
        .code-desc-l2{
          i{
            font-size: 20px;
            color: #F01D24;
          }
          span{
            margin-left: 8px;
            color: #F01D24;
            font-size: 16px;
          }
        }
        .code-desc-r{
          p{
            color: #5E5E66;
          }
          span{
            cursor: pointer;
            color: #4DA6FF;
          }
        }
      }
    }
    .submit-btn{
      margin-top: 37px;
      width: 100%;
      height: 56px;
      background-color: #093F87;
    }
    .qianshu{
      padding-top: 20px;
      .el-checkbox{
        color: #A6A6A6;
      }
      span{
        color: #093F87;
        cursor: pointer;
      }
    }
  }
  
</style>