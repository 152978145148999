import Vue from "vue";
import VueRouter from "vue-router";
import { getToken } from "@/utils/userInfo";
Vue.use(VueRouter);
const router = new VueRouter({
  routes: [
    {
      path: "*",
      redirect: "/home",
    },
    {
      path:'/miniWebH5',
      component: () => import("@/views/miniWebH5/index"),
      meta:{
        isMiniWeb:true,
        isShowNav: false,
        isHideBreadCrumb:false,
        hideAppNavMenu:true,
      }
    },
    {
      path:'/miniWebH5Dev',
      component: () => import("@/views/miniWebH5/indexDev"),
      meta:{
        isMiniWeb:true,
        isShowNav: false,
        isHideBreadCrumb:false,
        hideAppNavMenu:true,
      }
    },
    {
      path: "/home",
      name:'首页',
      component: () => import("@/views/home/index2"),
      meta: {
        isShowNav: true,
        isHideBreadCrumb:true,
        isShowHeader: true,
        isShowHeaderSticky: true,
        isShowFooter: true,
      },
    },
    // 资讯
    {
      path: "/information",
      component: () => import("@/views/information"),
      name:'资讯',
      redirect: "/information/tabs1",
      meta: {
        hideAppNavMenu: false,
        isShowNav: true,
      },
      children: [
        {
          path: "tabs1",
          component: () => import("@/views/information/tabs1"),
          name:'平台发布',
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "tabs2",
          component: () => import("@/views/information/tabs2"),
          name:'测评通告',
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "tabs3",
          component: () => import("@/views/information/tabs3"),
          name:'结果发布',
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "tabs4",
          component: () => import("@/views/information/tabs4"),
          name:'试验通知',
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "tabs2/detail",
          component: () => import("@/views/information/tabs2detail"),
          name:'详情',
          meta: {
            isTabs: false,
            isShowNav: true,
          },
        },
      ],
    },

    // 汽车测评
    {
      path: "/appraisal",
      component: () => import("@/views/appraisal"),
      name:'汽车测评',
      redirect: "/appraisal/tabs1",
      meta: {
        hideAppNavMenu: false,
        isShowNav: true,
      },
      children: [
        {
          path: "tabs1",
          component: () => import("@/views/appraisal/tabs1"),
          name:'测评专区',
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "tabs2",
          name:'消费者专区',
          component: () => import("@/views/appraisal/tabs2"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "tabs1/more",
          name:'更多测评',
          component: () => import("@/views/appraisal/tabs1/more"),
          meta: {
            parentBread:{
              name:'测评专区',
              path:'/appraisal/tabs1',
            },
            isTabs: false,
            isShowNav: true,
          },
        },
        {
          path: "detail",
          name:'详情',
          component: () => import("@/views/appraisal/detail"),
          meta: {
            isTabs: false,
            isShowNav: true,
          },
        },
      ],
    },

    // 车辆服务
    {
      path: "/vehicleService",
      name:'车辆服务',
      component: () => import("@/views/vehicleService"),
      redirect: "/vehicleService/detection",
      meta: {
        hideAppNavMenu: false,
        isShowNav: true,
      },
      children: [
        {
          path: "detection",
          name:'车辆检测',
          component: () => import("@/views/vehicleService/detection"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "repair",
          name:'维修报告',
          component: () => import("@/views/vehicleService/repair"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "insurance",
          name:'出险报告',
          component: () => import("@/views/vehicleService/insurance"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "battery",
          name:'电池报告',
          component: () => import("@/views/vehicleService/battery"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
      ],
    },
    // 推荐服务
    {
      path: "/referralService",
      name:'推荐服务',
      component: () => import("@/views/referralService"),
      meta: {
        hideAppNavMenu: false,
        isShowNav: true,
      },
    },
    // 二手车展厅
    {
      path: "/usedCar",
      component: () => import("@/views/usedCar"),
      meta: {
        isHideBreadCrumb:true,
        hideAppNavMenu: false,
        isShowNav: true,
      },
      children: [
        {
          path: "tabs1",
          component: () => import("@/views/usedCar/tabs1"),
          meta: {
            isHideBreadCrumb:true,
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "tabs2",
          name:'保值排行',
          component: () => import("@/views/usedCar/tabs2"),
          meta: {
            parentBread:{
              name:'二手车',
              path:'/usedCar/tabs1',
            },
            isTabs: true,
            isShowNav: true,
          },
        },
        // {
        // 	path: 'tabs1/more',
        // 	component: () => import('@/views/appraisal/tabs1/more'),
        // 	meta:{
        // 		isTabs:false,
        // 		isShowNav: true,
        // 	},
        // },
        {
          path: "detail",
          name:'车辆详情',
          component: () => import("@/views/usedCar/detail"),
          meta: {
            parentBread:{
              name:'二手车',
              path:'/usedCar/tabs1',
            },
            isTabs: false,
            isShowNav: true,
          },
        },
      ],
    },

    // 卖车估价
    {
      path: "/estimate",
      name:'卖车估价',
      component: () => import("@/views/estimate"),
      meta: {
        hideAppNavMenu: false,
        isShowNav: true,
      },
    },
    {
      path: "/estimate/detail",
      name:'估价详情',
      component: () => import("@/views/estimate/detail"),
      meta: {
        parentBread:{
          name:'卖车估价',
          path:'/estimate',
        },
        hideAppNavMenu: false,
        isShowNav: true,
      },
    },

    // 溯源查证
    {
      path: "/traceability",
      name:'溯源查证',
      component: () => import("@/views/traceability"),
      meta: {
        hideAppNavMenu: false,
        isShowNav: true,
      },
    },
    {
      path: "/traceability/detail",
      name:'溯源查证详情',
      component: () => import("@/views/traceability/detail"),
      meta: {
        parentBread:{
          name:'溯源查证',
          path:'/traceability',
        },
        hideAppNavMenu: false,
        isShowNav: true,
      },
    },
    {
      path: "/traceability/detail2",
      name:'溯源查证详情',
      component: () => import("@/views/traceability/detail/index2"),
      meta: {
        parentBread:{
          name:'溯源查证',
          path:'/traceability',
        },
        hideAppNavMenu: true,
        isShowNav: true,
      },
    },
    {
      path: "/personalCenter",
      name:'个人中心',
      component: () => import("@/views/personalCenter"),
      meta: {
        hideAppNavMenu: true,
        isShowNav: true,
      },
    },
    {
      path: "/personalCenter/detail",
      name:'工单详情',
      component: () => import("@/views/personalCenter/detail"),
      meta: {
        parentBread:{
          name:'个人中心',
          path:'/personalCenter',
        },
        hideAppNavMenu: true,
        isShowNav: true,
      },
    },
    {
      path: "/personalCenter/report",
      name:'报告详情',
      component: () => import("@/views/personalCenter/report"),
      meta: {
        parentBread:{
          name:'个人中心',
          path:'/personalCenter',
        },
        hideAppNavMenu: true,
        isShowNav: true,
      },
    },

    // 维修报告详情
    {
      path: "/personalCenter/maintenance",
      name:'维修报告详情',
      component: () => import("@/views/personalCenter/maintenance"),
      meta: {
        parentBread:{
          name:'个人中心',
          path:'/personalCenter',
        },
        hideAppNavMenu: true,
        isShowNav: true,
      },
      
    },
    {
      path: "/personalCenter/batteryDetail",
      name:'电池报告详情',
      component: () => import("@/views/personalCenter/batteryDetail"),
      meta: {
        parentBread:{
          name:'个人中心',
          path:'/personalCenter',
        },
        hideAppNavMenu: true,
        isShowNav: true,
      },
      
    },
    // 身份认证
    {
      path: "/personalCenter/authentication",
      name:'身份认证',
      component: () => import("@/views/personalCenter/authentication"),
      meta: {
        parentBread:{
          name:'个人中心',
          path:'/personalCenter',
        },
        hideAppNavMenu: true,
        isShowNav: true,
      },
    },
    // 关于我们
    {
      path: "/aboutUs",
      name:'关于我们',
      component: () => import("@/views/aboutUs"),
      meta: {
        hideAppNavMenu: false,
        isShowNav: true,
      },
      children: [
        {
          path: "synopsis",
          name:'公司简介',
          component: () => import("@/views/aboutUs/synopsis"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "introduce",
          name:'服务介绍',
          component: () => import("@/views/aboutUs/introduce"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "structure",
          name:'组织架构',
          component: () => import("@/views/aboutUs/structure"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
        {
          path: "contact",
          name:'联系我们',
          component: () => import("@/views/aboutUs/contact"),
          meta: {
            isTabs: true,
            isShowNav: true,
          },
        },
      ],
    },

    {
      path: "/login",
      component: () => import("@/views/login"),
      meta: {
        hideAppNavMenu: true,
        isShowNav:true
      },
    },
  ],
  // scrollBehavior(to, from, savedPosition) {
  //   // 始终滚动到顶部
  //   return { y: 0 };
  // },
});

// 前置守卫
router.beforeEach((to, from, next) => {
  // 判断是否有 token
  if (!getToken()) {
    // 未登录时权限不足
    if (
      to.name === "member" ||
      to.name === "order"
    ) {
      next("/login");
    }
    next();
  } else {
    // 有token但访问的是登陆注册
    if (to.path === "/login" || to.path === "register") {
      next("/");
    } else {
      next();
    }
  }
});

//全局后置守卫：初始化时执行、每次路由切换后执行
router.afterEach((to) => { // (to, from)
  if(to.path=='/login'){
    document.querySelector('html').style.overflow = 'hidden';
  }else{
    document.querySelector('html').style.overflow = 'auto';
  }
  document.body.scrollTo(0,0)
});
export default router;
