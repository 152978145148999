import request from '@/utils/request';
import qs from 'qs';

// 获取商品分类
export const categories = data =>{
	return request({
		url:`pms-boot/app-api/v1/categories`,
		method: 'get',
        params:data,
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 获取服务商列表
export const brandList = id =>{
	return request({
		url:`pms-boot/app-api/v1/categories/brandList?categoryId=${id}`,
		method: 'get',
		// params:data,
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 获取服务商回调地址
export const skusCallback = id =>{
	return request({
		url:`pms-boot/app-api/v1/skus/${id}`,
		method: 'get',
		// params:data,
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}




// 获取商品详情
export const getDetail = id =>{
	return request({
		url:`pms-boot/app-api/v1/spu/${id}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 商品分页列表
export const spuPages = data =>{
	return request({
		url:`pms-boot/app-api/v1/spu/pages`,
		method: 'post',
		data,
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 商品订单确认
export const omsConfirm = data =>{
	return request({
		url:`oms-boot/app-api/v1/orders/confirm`,
		method: 'post',
		data:qs.stringify(data),
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 商品提交订单
export const omsSubmit = data =>{
	return request({
		url:`oms-boot/app-api/v1/orders/submit`,
		method: 'post',
		data,
		headers: { 
			'Content-Type': 'application/json',
			Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 订单支付
export const omsPay = data =>{
	return request({
		url:`oms-boot/app-api/v1/orders/payment`,
		method: 'post',
		data,
		headers: { 
			'Content-Type': 'application/json',
			Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 扫码支付
export const omsQrcodePay = data =>{
	return request({
		url:`oms-boot/app-api/v1/orders/htmlPay`,
		method: 'post',
		data,
		headers: { 
			'Content-Type': 'application/json',
		},
	})
}

// 订单轮询
export const pollingOrder = orderNo =>{
	return request({
		url:`oms-boot/app-api/v1/orders/pollingOrder/${orderNo}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}

// 估价详情
export const estimateDetail = orderNo =>{
	return request({
		url:`vehicle-boot/api/v1/vehicle/valuation/serviceOrder/valuationDetail/${orderNo}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/json',
			// Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
		},
	})
}
