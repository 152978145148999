/*
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-02-21 11:00:53
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-15 10:46:10
 * @FilePath: \an-pc\src\store\modules\common.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import { getCategory } from '@/api/home';
export default {
	// 开启命名空间
	namespaced: true,
	state: {
		// 支付弹框数据
		aliPayHtml:'',
		payPrice:'',
    showPayMask:false,
    payTitle:'车辆检测服务',
		serviceList:[], // 服务机构数据
		// 手机号 - 弹框数据
		showMobileMask:false,
		mobileTitle:'询底价',
		successTitle:'', // 成功后提示标题
		successDesc:'', // 成功后提示详情
		usedCarData:{},
		/**
		 * '0':首页-免费高价估车  
		 * '1':二手车详情-我感兴趣
		 * '2':预约成功
		 * '3':纯登录
		 * */ 
		boxType:'0', 
		cityName:'', // 当前市名
		// 引导弹框
		showGuideMask:false,
		// 服务商弹框
		showServiceMask:false,
		/**
		 * 0:车辆服务引导弹框  
		 * 1:估价引导弹框
		 * */ 
		guideMaskShowType:0,
	},
	mutations: {
		setAliPayHtml(state, data) {
			console.log('setAliPayHtml=',data);
			state.aliPayHtml = data;
		},
	},
	actions: {
	},
};
